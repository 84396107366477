import { customLocalStorage } from '@crehana/utils';
import { LOCALSTORAGE_USER_SESSION_KEY } from "./constants";
function syncUserSession() {
  var sessionKey = null;
  var onVisibilityChangeHandler = function onVisibilityChangeHandler() {
    if (document.visibilityState === 'visible') {
      var userSession = customLocalStorage.getItem(LOCALSTORAGE_USER_SESSION_KEY);
      if (sessionKey !== userSession) {
        // If the user session has changed, reload the page to update the session
        window.location.reload();
      }
    }
  };
  var subscribe = function subscribe() {
    sessionKey = customLocalStorage.getItem(LOCALSTORAGE_USER_SESSION_KEY);
    document.addEventListener('visibilitychange', onVisibilityChangeHandler);
    window.onstorage = function () {
      // https://stackoverflow.com/questions/24077117/localstorage-in-win8-1-ie11-does-not-synchronize
      // Leave this empty
      // workaround for IE11 localstorage synchronization problem.
      // the event
    };
  };
  var unsubscribe = function unsubscribe() {
    document.removeEventListener('visibilitychange', onVisibilityChangeHandler);
  };
  return {
    subscribe: subscribe,
    unsubscribe: unsubscribe
  };
}
export default syncUserSession;