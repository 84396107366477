var createRecursiveFunction = function createRecursiveFunction(callback, options) {
  var fn = function fn() {
    if (options.condition()) {
      callback();
    } else {
      setTimeout(fn, options.optTimeout || 1000);
    }
  };
  return fn;
};
export default createRecursiveFunction;