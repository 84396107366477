import { useQuery } from '@apollo/client';
var GET_ORGANIZATION_BY_LEARNING_SLUG = { kind: "Document", definitions: [{ kind: "OperationDefinition", operation: "query", name: { kind: "Name", value: "AuthGetOrganizationByLearningSlug" }, variableDefinitions: [{ kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "organizationSlug" } }, type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }, directives: [] }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "get_organization_by_learning_slug" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "organization_slug" }, value: { kind: "Variable", name: { kind: "Name", value: "organizationSlug" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "slug" }, arguments: [], directives: [] }, { kind: "Field", name: { kind: "Name", value: "original_id" }, arguments: [], directives: [] }] } }] } }], loc: { start: 0, end: 177, source: { body: "query AuthGetOrganizationByLearningSlug($organizationSlug: String!) {\n  get_organization_by_learning_slug(organization_slug: $organizationSlug) {\n    slug\n    original_id\n  }\n}\n", name: "GraphQL request", locationOffset: { line: 1, column: 1 } } } };
var useCentralizedOrgSlug = function useCentralizedOrgSlug(_ref) {
  var _data$get_organizatio;
  var slug = _ref.slug;
  var _useQuery = useQuery(GET_ORGANIZATION_BY_LEARNING_SLUG, {
      context: {
        clientName: 'v5'
      },
      skip: !slug,
      variables: {
        organizationSlug: slug
      }
    }),
    data = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error;
  return {
    centralizedOrgSlug: (data === null || data === void 0 ? void 0 : (_data$get_organizatio = data.get_organization_by_learning_slug) === null || _data$get_organizatio === void 0 ? void 0 : _data$get_organizatio.slug) || null,
    loading: loading,
    error: error
  };
};
export default useCentralizedOrgSlug;